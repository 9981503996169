<template>
  <form class="group group relative mb-6 p-6 pb-8 border rounded-lg">
    <clear-button
      class="absolute -top-3 right-6 opacity-0 group-hover:opacity-100"
      @click.prevent="handleClose"
    />

    <address-typeahead
      name="address_line"
      :model-value="address.address_line || ''"
      :placeholder="
        $t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.ADDRESS_LINE', { no: 1 })
      "
      @select="selectAddressSuggestion"
    />
    <lf-input
      name="address_line2"
      :value="address.address_line2"
      :placeholder="`${$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.ADDRESS_LINE', {
        no: 2
      })} ${`(${$t('COMMON.OPTIONAL').toLowerCase()})`}`"
    />
    <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
      <lf-input
        name="city"
        :value="address.city"
        :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.CITY')"
      />
      <lf-input
        name="state"
        :value="address.state"
        :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.STATE')"
      />
    </div>
    <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
      <lf-input
        name="zip"
        :value="address.zip"
        :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.ZIP')"
      />
      <lf-input
        name="country"
        :value="address.country"
        :placeholder="$t('DEALS.DEAL_PROGRESS.BUSINESS_INFO.COUNTRY')"
      />
    </div>

    <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
      <lf-switch
        :model-value="address.is_primary"
        name="is_primary"
        class="flex-grow-0"
        :disabled="address.is_primary"
        @toggle-changed="updatePrimaryValue"
      />
      <p class="text-gray-700">
        {{ $t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.PRIMARY_ADDRESS") }}
      </p>
    </div>
  </form>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { watch } from "vue";
import { useForm } from "vee-validate";

import type { IAddress } from "@/models/common";

import AddressTypeahead from "@/components/ui/inputs/AddressTypeahead.vue";
import ClearButton from "@/components/ui/buttons/ClearButton.vue";

const props = defineProps({
  address: {
    type: Object as PropType<IAddress>,
    default: () => ({
      address_line: "",
      address_line2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      is_primary: false,
      id: null
    })
  }
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "update:address", address: IAddress): void;
  (e: "updatePrimaryValue"): void;
}>();

const { values, setValues, setFieldValue } = useForm({
  initialValues: {
    ...props.address
  }
});

const selectAddressSuggestion = (address: Partial<IAddress>) => {
  setValues({
    ...values,
    ...address
  });
};

const handleClose = () => {
  emit("close");
};

const updatePrimaryValue = (value: boolean) => {
  setFieldValue("is_primary", value);
  emit("updatePrimaryValue");
};

watch(
  values,
  () => {
    emit("update:address", {
      ...values,
      id: props.address.id
    });
  },
  {
    deep: true
  }
);
</script>
